@import '../../../styles/_colors.scss';

.wrapper-content {
  transition: margin-left 0.3s ease-in-out;
  position: relative;
  height: calc(100vh - 6rem);

  .sidenav-toggler {
    position: fixed;
    bottom: 2rem;
    background-color: rgba($gray-40, 0.5);

    padding-left: 0.75rem;
    padding-top: 0.25rem;
    width: 2.5rem;
    height: 2.25rem;
    cursor: pointer;
    border-radius: 5px;
    transition: left 0.3s ease-in-out;
    z-index: 99999999;
  }
}
