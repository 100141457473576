.streak-number-overlay {
  font-size: 0.75rem;
  width: inherit;
  height: inherit;
  position: relative;
  top: -1.5rem;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home {
  font-weight: 600;
  color: #333;
}
