@import '_colors.scss';

.no-teams-text {
  width: 100%;
  color: rgba($white, 0.4);
  font-size: 0.75rem;
  margin: 5rem 0;
  text-align: center;
}

.sub-page-bar {
  background-color: rgba($black, 0.85);
  position: fixed;
  width: 100%;
  border-bottom: 1px solid $gray-40;
  height: 5rem;
  z-index: 10;
  backdrop-filter: blur(10px);

  @media (max-width: 481px) {
    overflow-x: scroll;
  }

  .scrollable {
    min-width: 50rem;

    .streaks-legend {
      width: 24rem;
      font-size: 0.625rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      div > div {
        color: rgba($white, 0.7);
        display: flex;
      }
    }
  }
}

.sub-page-bar::-webkit-scrollbar {
  display: none;
}

.sub-page-breadcrumb {
  text-transform: capitalize;
  color: $white;
  font-family: DM Sans;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.5px;

  margin: 1rem;
}

.sub-page-selector {
  padding: 0 1rem;
  display: flex;
  width: 100%;

  .sub-page-tab {
    font-family: DM Sans;
    font-size: 1rem;
    font-weight: 500;
    color: $gray-40;
    text-transform: uppercase;
    margin-right: 2rem;
    cursor: pointer;
  }

  .sub-page-tab.selected {
    color: $wt-green;
    border-bottom: 1px solid $wt-green;
  }
}

.streaks-container {
  display: flex;
  margin: 1rem 0 0 1rem;

  .summary-container {
    margin-right: 2rem;
    min-width: 24rem;

    .summary-title-bar {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      color: $white;
      font-family: DM Sans;
      font-size: 1rem;
      font-weight: 500;

      .filter-button {
        color: $wt-green;
        font-family: DM Sans;
        font-size: 0.75rem;
        font-style: italic;
        font-weight: 500;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    .team-summary {
      display: flex;
      align-items: center;
      font-size: 0.75rem;
      margin-bottom: 0.25rem;

      .team-name {
        width: 8rem;
      }

      .streak-bar {
        height: 1rem;
      }

      .streak-text {
        margin-left: 0.25rem;
        text-transform: capitalize;
      }
    }
  }

  .team-streaks-container {
    .team-header {
      display: flex;
      align-items: center;

      color: $white;
      font-family: DM Sans;
      font-size: 1rem;
      font-weight: 500;

      img {
        height: 4rem;
        width: 4rem;
        object-fit: contain;
        margin-right: 0.5rem;
      }
    }

    .streak-card {
      padding: 1rem;
      height: 12rem;

      .donut-chart-container {
        height: 100%;
        width: 10rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .title {
          color: $white;
          font-size: 0.75rem;
          font-weight: 400;
        }

        .donut-chart {
          position: relative;

          .percent-legend {
            color: $wt-green;
            font-size: 1rem;
            font-weight: 500;
            text-align: center;

            position: absolute;
            width: 130px;
            margin-top: 52px;
          }
        }
      }

      .streak-chart-container {
        display: flex;

        .streak-column {
          display: flex;
          flex-direction: column;
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.line-movement-page {
  min-width: 900px;

  .line-movement-header {
    width: 100%;
    color: $white;
    font-size: 1.25rem;
    font-weight: 500;

    .current-date {
      margin-top: 12px;
      font-size: 0.75rem;
      color: rgba($white, 0.7);
    }
  }

  .line-movement-container {
    min-width: 8rem;
    padding-top: 5rem;
    color: $white;
    font-size: 0.875rem;

    .line {
      height: 1rem;

      div {
        height: 100%;
      }
    }
  }

  @media (max-width: 481px) {
    .line-movement-container {
      padding-top: 4rem;
    }
  }

  .stats-container {
    min-width: 4rem;
    color: $white;
    font-size: 0.875rem;

    .center {
      text-align: center;
    }

    .right {
      text-align: right;
    }

    .header-row {
      font-size: 1rem;
      height: 5rem;
      padding: 1rem 0;
    }

    @media (max-width: 481px) {
      .header-row {
        color: rgba($white, 0.7);
        font-size: 0.75rem;
        height: 4rem;
      }
    }

    .game-row {
      height: 1rem;

      div {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .no-games-text {
    color: rgba($white, 0.4);
    font-size: 0.75rem;
    margin: 5rem 0;
    text-align: center;
  }
}

.public-page {
  font-size: 0.875rem;

  .card-header {
    color: $white;
    font-size: 1rem;
    font-weight: 500;

    margin: 1rem 0 0.5rem 0;
  }

  .public-page-logo {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
    margin-right: 0.5rem;
  }

  .public-data-card {
    padding: 1rem;
    max-width: 70rem;

    .public-data-header-row {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    .public-data-row {
      margin-bottom: 1rem;
    }

    .public-data-row > div {
      margin-bottom: 0.75rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
  }

  .pct-bar-container {
    height: 1rem;
    display: flex;
    align-items: center;

    .pct-bar-label {
      font-size: 0.875rem;
      margin-right: 0.25rem;
    }

    .pct-bar-fill {
      height: 100%;
      background-color: $wt-green;
    }
  }
}

.match-up-page {
  display: flex;
  margin-top: 1rem;

  .upcoming-dates-container {
    width: 16rem;
    margin-right: 1.5rem;
    font-size: 1.25rem;
    color: $white;
    font-weight: 500;

    .date-selector {
      display: flex;
    }

    .game-card {
      display: flex;
      padding: 0.5rem;
      margin-bottom: 0.5rem;

      .date {
        color: $white;
        font-size: 0.5rem;
        font-weight: 400;
        width: 33%;
      }

      .team-row {
        display: flex;
        font-size: 0.625rem;
        color: $white;
        font-weight: 400;
        margin-bottom: 0.5rem;

        img {
          width: 1rem;
          height: 1rem;
          margin-right: 0.25rem;
        }
      }
    }
  }

  .team-entry-container {
    .team-entry {
      margin-bottom: 1.5rem;

      .team-header {
        display: flex;
        align-items: center;

        img {
          width: 3rem;
          height: 3rem;
          margin-right: 1rem;
        }

        div {
          color: $white;
          font-size: 1.25rem;
          font-weight: 500;
        }
      }

      .header {
        font-size: 1rem;
        font-weight: 500;
      }
      .header.data-type {
        color: $wt-green;
      }

      .stat-card {
        width: 18rem;
        display: flex;
        justify-content: space-between;
        padding: 1rem;

        .text-stats {
          color: $white;
          font-size: 1rem;
          font-weight: 500;

          .win {
            color: $wt-green;
          }
          .loss {
            color: $wt-red;
          }
          .push {
            color: $wt-blue;
          }
        }
      }
    }
  }
}

// MUI Overrides
.MuiDataGrid-root {
  border-radius: 0 !important;
}
