@import 'styles/_colors.scss';

body {
  background-color: $black;
  color: $white;

  margin: 0;
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
