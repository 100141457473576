@import '../../../styles/colors.scss';

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 5rem;
  padding: 2rem;

  .no-data {
    color: $gray-40;
  }

  .error {
    color: $wt-red;
  }
}
