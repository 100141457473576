@import '../../../styles/_colors.scss';

.date-team-picker {
  height: 2rem;
  margin: 0.5rem 0;

  .date {
    text-align: center;
    width: 3rem;
    height: 2rem;
    margin: 0 2px;
    font-size: 0.75rem;
    border: 1px solid rgba($white, 0.4);
    border-radius: 5px;
    line-height: 125%;
    cursor: pointer;
    transition: background-color 0.2s;
    white-space: pre;
    color: rgba($white, 0.7);
  }

  .date.selected {
    border: 1px solid $wt-green;
    color: $white;
  }

  .date:hover {
    background-color: rgba($white, 0.1);
  }

  .react-datepicker-popper {
    z-index: 100 !important;
    overflow: auto;
  }

  .title-text {
    color: $wt-green;
    font-style: italic;
    font-size: 0.75rem;
    text-decoration: underline;
    cursor: pointer;
  }

  button.date-picker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.25rem;
    margin: 0 2px;

    width: 4rem;
    height: 2rem;

    color: var(--White, #fff);
    font-size: 0.75rem;
    font-weight: 500;

    border-radius: 5px;
    border: 1px solid var(--Green, #7eab46);
    background: transparent;
    transition: background-color 0.2s;
  }

  button.date-picker:hover {
    background-color: rgba($white, 0.1);
  }
}
