@import '../../../styles/_colors.scss';

.wt-sidenav {
  z-index: 10;
  margin-top: 6rem !important;
  width: 12rem !important;
  background-color: $gray-20;
  color: $white;

  .sidenav-content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 2rem);
    margin: 0 1rem;

    .collapse-chevron {
      color: $wt-green;
      opacity: 0;
      transform: rotate(0deg);
      transition:
        transform 0.3s,
        opacity 0.3s;
    }

    .collapse-chevron.rotate {
      opacity: 1;
      transform: rotate(180deg);
    }

    .sidenav-item-title {
      color: $white;
      text-transform: uppercase;
      margin-top: 0.75rem;
      font-weight: 500;
      font-size: 0.875rem;
      display: flex;
      justify-content: space-between;
      transition: color 0.3s;
    }

    .sidenav-item-title.green {
      color: $wt-green;
    }

    .collapse.scroll {
      overflow-y: auto !important;
    }
  }
}

// Scrollbar styles
.collapse::-webkit-scrollbar-track {
  background-color: $gray-20;
}

.collapse::-webkit-scrollbar {
  width: 6px;
  background-color: $gray-20;
}

.collapse::-webkit-scrollbar-thumb {
  background-color: rgba($gray-60, 0.2);
}
