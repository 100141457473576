@import '../../../styles/_colors.scss';

.team-selector {
  .date {
    font-size: 0.6rem;
    border: 1px solid white;
    margin-left: 0.25rem;
  }

  .action-text {
    color: $white;
    font-style: italic;
    font-size: 0.75rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .teams-list {
    padding-left: 1rem;

    .team {
      color: $gray-60;
      font-size: 0.875rem;
      font-weight: 500;
      list-style-type: none;
      cursor: pointer;
      margin-bottom: 0.25rem;
    }

    .team.selected {
      color: $white;
      list-style-type: disc;
    }
  }
}
