$wt-green: #7eab46;
$wt-blue: #425c98;
$wt-red: #c33636;

$black: #17151c;
$gray-20: #2e2c32;
$gray-40: #4e4d52;
$gray-60: #858488;
$gray-80: #bebcbf;
$white: #ffffff;

$color-linear-gradient: linear-gradient(
  18deg,
  #7eab46 38.54%,
  #425c98 84.9%,
  #c33636 100%
);
$dark-linear-gradient: linear-gradient(
  180deg,
  #2e2c32 0%,
  #222027 52.08%,
  #17151c 100%
);

$card-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
