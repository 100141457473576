@import 'styles/_colors.scss';

a {
  text-decoration: none;
  color: inherit;
}

.App {
  width: 100vw;
  height: 100vh;
  font-family: 'DM Sans', sans-serif;
  text-align: center;

  color: $white;

  font-size: 16px;
}

// Scrollbar styles
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($gray-60, 0.2);
}

textarea::-webkit-scrollbar {
  width: auto !important;
}

:root {
  --wt-green: #{$wt-green};
  --wt-blue: #{$wt-blue};
  --wt-red: #{$wt-red};
  --black: #{$black};
  --gray-20: #{$gray-20};
  --gray-40: #{$gray-40};
  --gray-60: #{$gray-60};
  --gray-80: #{$gray-80};
  --white: #{$white};
}
