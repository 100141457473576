@import '../../../styles/_colors.scss';

.odds-selector {
  .odds-list {
    margin-top: 0.25rem;
    margin-bottom: 0;
    padding-left: 1rem;

    .odds {
      color: $gray-60;
      font-size: 0.875rem;
      font-weight: 500;
      list-style-type: none;
      cursor: pointer;
      margin-bottom: 0.25rem;
    }

    .odds.selected {
      color: $white;
      list-style-type: disc;
    }
  }
}
