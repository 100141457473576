@import '../../../styles/_colors.scss';

.date-selector {
  .react-datepicker-popper {
    position: fixed !important;
    z-index: 9999 !important;
  }

  .title-text {
    color: $wt-green;
    font-style: italic;
    font-size: 0.75rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .between-date-arrow {
    size: 14px;
    font-weight: 600;
    margin: 0 0.25rem;
  }

  button.date-picker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.25rem;

    width: 4.25rem;
    height: 1.5rem;

    color: var(--White, #fff);
    font-size: 0.75rem;
    font-weight: 500;

    border-radius: 5px;
    border: 1px solid var(--Green, #7eab46);
    background: var(--b-75, #4e4d52);
  }
}
