@import '../../../styles/_colors.scss';

.percent-container {
  align-items: center;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 2px 8px;
  min-width: 64px;

  &.high {
    background-color: $wt-green;
  }

  &.low {
    background-color: $wt-red;
  }

  .percent {
    font-size: 18px;
  }

  .record {
    font-size: 12px;
  }
}
