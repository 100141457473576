@import '../../../styles/_colors.scss';

.icon-nav-link-container {
  display: flex;
  margin: 1rem;

  .logo-container {
    width: 2rem;
    height: 2rem;
    background-color: $gray-40;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem;
    margin-right: 0.6rem;
  }

  .text-container {
    height: 2rem;
    font-weight: 700;
    color: $white;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  .text-container.selected {
    color: $wt-green;
  }
}
